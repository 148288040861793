	
export const onClientEntry = () => {
	// Focus trap for mobile nav
	let firstLoad = true;
	let elNavLastElement = ''
	let elNavFirstElement = ''
	let elNavPreviouslyFocusedElement = ''

	const handleFocus = e => {
		
		if (firstLoad === true) {
			firstLoad = false;
			elNavLastElement = document.querySelector('#main-nav ul .main-nav__last-element');
			elNavFirstElement = document.querySelector('#main-nav-toggle-btn');
			elNavPreviouslyFocusedElement = '';
		}
		
		if (elNavPreviouslyFocusedElement === elNavLastElement) {
			elNavFirstElement.focus();
			elNavPreviouslyFocusedElement = '';
			
		} else {
			elNavPreviouslyFocusedElement = e.target;
		}
	}

	document.addEventListener("focus", handleFocus, true);

}
